const LINKS = {
    "ROOT": "/",
    "WORK": "/work",
    "MENTORING": "/mentoring",
    "IMPRESSUM": "/impressum"
};


export {
    LINKS
}
